ul,
li {
  padding: 0;
}

ol,
ul,
dl {
  margin-bottom: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  line-height: 1;
  height: 100%;
}

ol,
ul {
  list-style: none;
}

#app {
  height: 100%;
}

/* stylelint-disable-next-line selector-id-pattern */
#EIMOS-APP {
  height: 100%;
}

.ant-notification > div:not(:empty) {
  padding: 0 15px 30px 30px;
}

#appstudio-container > div {
  height: 100%;
}

:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-text: #333;
  --adm-color-text-secondary: #666;
  --adm-color-weak: #999;
  --adm-color-light: #ccc;
  --adm-color-border: #eee;
  --adm-color-background: #fff;
  --adm-color-white: #fff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue', arial, 'PingFang SC',
    'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
  --label-color: #556b82;
}

html[data-prefers-color-scheme='dark'] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-border-color: var(--adm-color-border);
}

:root {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}

a,
button {
  cursor: pointer;
}

a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}

a:active {
  opacity: 0.8;
}

// tailwind 与 antd 样式冲突
svg {
  vertical-align: baseline !important;
  // display: inline-block !important;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 8px;

  /* 高宽分别对应横竖滚动条的尺寸 */
  height: 8px;
}

::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 8px;
  background: #d8d8d8;
}

::-webkit-scrollbar-thumb:hover {
  /* 滚动条里面小方块 */
  background: skyblue;
}

::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  border-radius: 8px;
  background-color: transparent;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.ant-notification-notice {
  padding: 16px 34px 16px 24px;
}

.ant-notification-notice.ant-notification-notice-error {
  background: #fff1f0;
  border-top: 4px solid #e84739;
}

.ant-notification-notice.ant-notification-notice-warning {
  background: #fef8e5;
  border-top: 4px solid #f98e1c;
}

.ant-notification-notice.ant-notification-notice-warn {
  background: #fef8e5;
  border-top: 4px solid #f98e1c;
}

.ant-notification-notice.ant-notification-notice-success {
  background: #f6ffed;
  border-top: 4px solid #52c41a;
}

.ant-notification {
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  // .ant-notification-notice {
  //   padding-left: 0;
  // }

  // .ant-notification-notice-icon {
  //   margin-left: -5px;
  // }

  .ant-notification-notice-with-icon {
    min-height: 24px;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 38px;
  }

  .ant-notification-notice-close {
    font-size: 16px;
    right: 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-modal-header {
  cursor: move;
  user-select: none;
  padding: 0;
  border: none;
  box-shadow: 0 2px 0 0 rgb(193 193 193 / 30%);
}

.ant-modal-close-x {
  width: 48px;
  height: 48px;
  font-size: 14px;
  line-height: 48px;
  color: black;
}

.ant-modal-close-x:hover {
  color: #0064ce;
}

.ant-modal-header:hover {
  background-color: #e6f7ff;
  cursor: move;
}

.ant-modal-content {
  height: 100%;
}

.ant-drawer-body {
  padding: 0;
}

.ant-input-number-disabled:hover {
  border: 1px solid #f1f3f5;
}

.ant-input-number-group .ant-input-number-disabled:hover {
  border: 1px solid #f1f3f5;
}

.ant-input-affix-wrapper-disabled {
  background-color: #f1f3f5;
  border-color: #f1f3f5;
}

.ant-input {
  padding: 6px 11px;
}

.ant-notification-notice .ant-btn {
  min-width: 0;
  font-size: 12px;
  border: none;
}

// ————----————按钮改动————----————
.ant-modal-body {
  padding: var(--md-padding) var(--lg-padding);
}

.ant-modal-footer {
  padding: 11px 23px;
  border-top: 1px solid #c4ccd2;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
  border-radius: 4px;
  height: 32px;
  min-width: 72px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: 0;
  margin-right: 8px;
}

.ant-popover-buttons {
  display: flex;
  flex-direction: row-reverse;
}

/* stylelint-disable-next-line no-descending-specificity */
.ant-popover-buttons .ant-btn {
  border-radius: 4px;
  height: 24px;
  min-width: 54px;
  margin-right: 8px;
}

.ant-input[disabled]:hover {
  border-color: #f1f3f5;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border: none;
  box-shadow: none;
}
.ant-btn-icon-only {
  box-shadow: none !important;
}
.atom-select-container .ant-btn-icon-only {
  box-shadow: 0px 1px 0px 0px var(--box-shadow-normal) !important;
}
.ant-input-number-group-addon:has(+ .ant-input-number-disabled),
.ant-input-number-group-addon + .ant-input-number-disabled,
.ant-input-group-addon:has(+ .ant-input-disabled),
.ant-input-group-addon + .ant-input-disabled {
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
}

// ————----————最小字体改动————----————
.ant-table.ant-table-small,
.ant-btn,
.ant-form-item-label > label,
.ant-tree,
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab,
.ant-table.ant-table-middle {
  font-size: var(--adm-font-size-main);
}

// ——————————Image组件被Tailwind影响————————————
.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eimos-is-loading {
  overflow: hidden;
}
// ——————————tab-liading————————————
.tab-loading-circle {
  display: inline-block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #136cf6;
  margin: 0 1px;
}

@keyframes grow {
  0%,
  50%,
  100% {
    transform: scale(0.5);
    background-color: #136cf6;
  }

  25% {
    transform: scale(1);
    background-color: #0038c9;
  }
}

.tab-loading-animation1 {
  animation: 1.6s cubic-bezier(0.32, 0.06, 0.85, 1.11) 0s infinite normal none running grow;
}

.tab-loading-animation2 {
  animation: 1.6s cubic-bezier(0.32, 0.06, 0.85, 1.11) 0.2s infinite normal none running grow;
}

.tab-loading-animation3 {
  animation: 1.6s cubic-bezier(0.32, 0.06, 0.85, 1.11) 0.4s infinite normal none running grow;
}
