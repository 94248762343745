.ding-dtflogin {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e8e8;
}

@media screen and (max-width: 500px) {
  .ding-dtflogin {
    background-color: #fff !important;
  }
}
